var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_vm._m(0),_c('div',{staticClass:"bg-white w-full p-6 rounded-lg shadow"},[_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-wrap -mx-3 mb-6"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",attrs:{"for":"grid-first-name"}},[_vm._v(" First Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white",class:{
              'is-invalid': _vm.form.errors.has('name'),
            },attrs:{"id":"grid-name","type":"text","placeholder":"Enter agent name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}})]),_c('div',{staticClass:"w-full md:w-1/2 px-3"},[_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",attrs:{"for":"grid-phone"}},[_vm._v(" Phone ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",class:{
              'is-invalid': _vm.form.errors.has('phone'),
            },attrs:{"id":"phone","type":"number","placeholder":"enter agents phone number"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone", $event.target.value)}}})])]),_c('div',{staticClass:"flex flex-wrap -mx-3 mb-6"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",attrs:{"for":"grid-address"}},[_vm._v(" Type ")]),_c('select',{staticClass:"bg-gray-200 w-full px-2 py-4",attrs:{"id":"grid-type","name":""},on:{"change":_vm.handleType}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select your option")]),_c('option',{attrs:{"value":"admin"}},[_vm._v("Admin")]),_c('option',{attrs:{"value":"teller"}},[_vm._v("Teller")])])]),_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-6 md:mb-0"},[_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",attrs:{"for":"password"}},[_vm._v(" Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",class:{
              'is-invalid': _vm.form.errors.has('password'),
            },attrs:{"id":"password","type":"text","placeholder":"enter agents password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}})])]),_c('div',{staticClass:"w-full md:w-1/2 mb-6 md:mb-0"},[_c('label',{staticClass:"block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",attrs:{"for":"password"}},[_vm._v(" Branch ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.branch),expression:"form.branch"}],staticClass:"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",class:{
            'is-invalid': _vm.form.errors.has('branch'),
          },attrs:{"id":"branch","type":"text","placeholder":"Enter branch"},domProps:{"value":(_vm.form.branch)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "branch", $event.target.value)}}})]),_c('div',{staticClass:"flex flex-wrap -mx-3 px-3 mt-8"},[_c('button',{staticClass:"px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.isLoading ? "loading..." : "Save")+" ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between items-center mb-6"},[_c('div',[_c('h1',{staticClass:"text-4xl"},[_vm._v("Create a new User")])])])
}]

export { render, staticRenderFns }