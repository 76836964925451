<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Create a new User</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="bg-white w-full p-6 rounded-lg shadow">
      <form class="w-full" @submit.prevent="onSubmit">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              First Name
            </label>
            <input
              v-model="form.name"
              :class="{
                'is-invalid': form.errors.has('name'),
              }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-name"
              type="text"
              placeholder="Enter agent name"
            />
            <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-phone"
            >
              Phone
            </label>
            <input
              v-model="form.phone"
              :class="{
                'is-invalid': form.errors.has('phone'),
              }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="phone"
              type="number"
              placeholder="enter agents phone number"
            />
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-address"
            >
              Type
            </label>

            <select
              id="grid-type"
              class="bg-gray-200 w-full px-2 py-4"
              @change="handleType"
              name=""
            >
              <option value="" disabled selected>Select your option</option>
              <option value="admin">Admin</option>
              <option value="teller">Teller</option>
            </select>

            <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
          </div>

          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="password"
            >
              Password
            </label>

            <input
              v-model="form.password"
              :class="{
                'is-invalid': form.errors.has('password'),
              }"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="password"
              type="text"
              placeholder="enter agents password"
            />
            <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
          </div>
        </div>

        <div class="w-full md:w-1/2 mb-6 md:mb-0">
          <label
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            for="password"
          >
            Branch
          </label>

          <input
            v-model="form.branch"
            :class="{
              'is-invalid': form.errors.has('branch'),
            }"
            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="branch"
            type="text"
            placeholder="Enter branch"
          />
          <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
        </div>

        <div class="flex flex-wrap -mx-3 px-3 mt-8">
          <button
            class="px-4 py-2 rounded text-white inline-block shadow-lg bg-blue-500 hover:bg-blue-600 focus:bg-blue-700"
            type="submit"
          >
            {{ isLoading ? "loading..." : "Save" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
// import BranchSales from "../../components/Charts/BranchSales";

import { mapGetters } from "vuex";
// import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  middleware: "auth",
  layout: "default",

  components: {
    // BranchSales,
    // HelloWorld
  },

  //   mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  mounted() {
    console.log("user", this.user.data.type);
    if (!this.user.data.type == "admin") {
      // console.log("Hereeeeeeeeeeeeeeeeeee");
      this.$router.go(-1);
      return false;
    }
  },
  data: () => ({
    isLoading: false,

    form: new Form({
      name: "",
      phone: "",
      type: "teller",
      branch: "",
      password: "",
    }),
  }),

  methods: {
    handleType(e) {
      this.form.type = e.target.value;
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        console.log("form data", this.form);
        await this.form.post("/agent/user", this.form);
        this.isLoading = false;
        this.$router.push({ path: "/agents" });
        return true;
      } catch (e) {
        this.isLoading = false;
        console.log("Error");
        return false;
      }
    },
  },
};
</script>
